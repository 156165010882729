import { TProductAttributes, TProductVariants } from '@components/ProductCarousel/v2/types';
export const THUMBNAIL_SIZE = '44px';
const MEDIA_BASES = '/media/attribute/swatch'; // default path to attributes

const getOptionsByCode = (array, code) => array.find((i) => i.attributeCode === code);

const hasMatchingAttributes = (attributes: TProductAttributes[], attributeUid: string) =>
    attributes.some(({ uid }) => uid === attributeUid);

const findMatchingVariant = (value: string, options: TProductVariants[]) => {
    if (value && options?.length) {
        return options.find(({ productAttributes }) => hasMatchingAttributes(productAttributes, value));
    }
};
export const productSwatches = (productItemUrl, configurableOptions, variants) => {
    if (!configurableOptions?.length || !variants.length) {
        return [];
    }
    const swatchUrl = new URL(productItemUrl).origin;
    const options = getOptionsByCode(configurableOptions, 'color');
    const newValues =
        options &&
        options.values.reduce((array, value) => {
            const { uid } = value;
            const findMatch = findMatchingVariant(uid, variants);
            if (!findMatch) {
                return array;
            }
            const { imageUrl } = findMatch;
            const imgUrl = new URL(imageUrl);
            const colorAttribute = findMatch.productAttributes.filter((attribute) => attribute.label == value.label)[0];
            const { colorGroup } = colorAttribute || {};
            imgUrl.searchParams.set('width', THUMBNAIL_SIZE);
            imgUrl.searchParams.set('height', THUMBNAIL_SIZE);
            const item = {
                ...value,
                color: value.label,
                colorGroup: colorGroup,
                hasColor: !!value.swatchValue,
                id: uid,
                image: MEDIA_BASES + value.swatchValue,
                mainImage: imgUrl.toString(),
                thumbnail: imgUrl.pathname + imgUrl.search,
            };

            return [...array, item];
        }, []);

    return newValues;
};
